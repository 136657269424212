import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { StartupService } from "./services/startup.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "bgratingweb";

  constructor(
    private router: Router,
    private http: HttpClient,
    private startupService: StartupService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRouteData.state : "";
  }

  get showHeader(): boolean {
    return (
      this.authService.BlueShipUser != null &&
      this.authService.EnterpriseProfile != null
    );
  }
}
