import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { AbstractValueAccessor, MakeProvider } from "./AbstractValueAccessor";
import { Observable, Subject } from "rxjs";
import { Customer } from "../../models/Customer";
import { CustomerService } from "../../services/customer.service";
import { AuthService } from "src/app/services/auth.service";
import { ShipmentClient } from "src/app/services/ShipmentClient";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-customer-search",
  templateUrl: "./customer-search.component.html",
  styleUrls: ["./customer-search.component.scss"],
  providers: [MakeProvider(CustomerSearchComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSearchComponent extends AbstractValueAccessor {
  customers: Customer[];
  customersSource: Customer[];
  customers$: Observable<Customer[]>;
  searchTerm$ = new Subject<string>();
  isRestrictedAccess: boolean = false;
  singleCustomerSelected: Customer;

  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private shipmentClient: ShipmentClient,
    private toastr: ToastrService
  ) {
    super();

    this.customers$ = this.customerService.search(this.searchTerm$);
  }

  onSelect(item: any) {
    this.writeValue(item);
  }

  handleFilter(filter: string) {
    this.searchTerm$.next(filter);
  }

  handleFilterGroupAgent(filter: any) {
    console.log(filter);
    this.customers = this.customersSource.filter(
      (s) => s.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  fetchUserEnterprises() {
    return this.shipmentClient.get<Customer[]>(
      `Enterprise/${this.authService.BlueShipUser.enterpriseID}/children`
    );
  }

  onEnterpriseChange(event: any) {
    if (!event) {
      this.writeValue(event);
      return;
    }
    this.customerService
      .getCustomerByAccountNumber(event.accountNumber)
      .subscribe(
        (cust) => {
          this.writeValue(cust);
          this.singleCustomerSelected = cust;
        },
        (err) => {
          console.log(err);
          this.toastr.error("Account not configured for BG Rating.");
        }
      );
  }
}
